.LoginForm {
    justify-content: end;
}

.logo {
    display: block;
    margin-right: auto;
    margin-left: auto;
    max-width: 600px;
    width: 90%;
}