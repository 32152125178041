.bottom-margin {
    margin-bottom: 10px !important;
}

.bottom-margin.form-group {
    margin-bottom: 10px !important;
}

.submit-button {
    margin-top: 10px;
    padding-top: 10px
}

.header {
    padding: 10px 0px 20px 0px;
}

.container-sm {
    margin-left: 0px;
}