.form-group {
    margin-bottom: 0px !important;
}

.login-field {
    margin-top: 3.5px;
}

.logged-in{
    padding-right: 10px;
}