.container-sm {
    margin-left: 0px;
    padding: 20px !important;
}

.submit-button {
    margin-top: 10px;
    margin-bottom: 10px;
}

.bottom-margin {
    margin-bottom: 10px !important;
}